import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import Image from 'components/image';
import SEO from 'components/seo';
import Links from 'components/links';
import { LinkLabel } from 'utils/constants';
import { postMap, slugify } from 'utils/post-map';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Picking The Mission For The Week`}</h1>
    <p><strong parentName="p">{`Yo,`}</strong></p>
    <p><strong parentName="p">{`Monday is the week kickoff.`}</strong></p>
    <p><strong parentName="p">{`First things first - deciding on our mission.`}</strong></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "89.99999999999999%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAASABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAECAwX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHsaUuSkY2DUH//xAAaEAADAAMBAAAAAAAAAAAAAAAAAQIDEBES/9oACAEBAAEFAlE4zr16mjiODSROv//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABgQAAMBAQAAAAAAAAAAAAAAAAEQIREC/9oACAEBAAY/As5Dh1yP/8QAHBAAAgIDAQEAAAAAAAAAAAAAAAERMSFRYUGR/9oACAEBAAE/IVFC9nAl6fwtpSsMVxDwYpKk4ZzIj//aAAwDAQACAAMAAAAQMAgA/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPxAf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPxAf/8QAGhABAAMBAQEAAAAAAAAAAAAAAQARITFxYf/aAAgBAQABPxDGNKxvT3sE468hXoMYCC7E6PIqteLAyrB8WOlGoaLvuQCquq7ABQB5P//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Picking the mission Slack",
          "title": "Picking the mission Slack",
          "src": "/static/f413523ba20626ee92f402dfdb23d86b/04bec/mission-slack-convo.jpg",
          "srcSet": ["/static/f413523ba20626ee92f402dfdb23d86b/a6407/mission-slack-convo.jpg 400w", "/static/f413523ba20626ee92f402dfdb23d86b/a24e6/mission-slack-convo.jpg 800w", "/static/f413523ba20626ee92f402dfdb23d86b/04bec/mission-slack-convo.jpg 1600w", "/static/f413523ba20626ee92f402dfdb23d86b/ceb6c/mission-slack-convo.jpg 1699w"],
          "sizes": "(max-width: 1600px) 100vw, 1600px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><strong parentName="p">{`Why build an email list?`}</strong></p>
    <p>{`Our strategy for the course is to first build an engaged audience (free email
list) and then offer them an upsell (premium course).`}</p>
    <p>{`To build the list - Ben is using facebook ads. With a goal of acquiring
subscribers for less than 50 cents a pop.`}</p>
    <p><strong parentName="p">{`We settled on 3 ad styles:`}</strong></p>
    <ul>
      <li parentName="ul">{`Memes`}</li>
      <li parentName="ul">{`Straightforward offer (learn about X)`}</li>
      <li parentName="ul">{`Specific (promoting a specific juicy blog post)`}</li>
    </ul>
    <p>{`At the bottom of this email is a screencast of him setting up the ads in case
you wanna see it. Neither of us are ad experts, but you don’t need to be a
genius to get results.`}</p>
    <p>{`Next we started brainstorming who we could partner with that brings
`}<strong parentName="p">{`expertise`}</strong>{` about the topic to the table.`}</p>
    <p>{`Normally people launch courses about things they are experts at. But I think you
can actually create a profitable course even if you’re a total beginner - as
long as the topic is one a lot of people are interested in.`}</p>
    <p>{`Put differently. Picking the right topic is a lot more important than being a
knowledgeable expert.`}</p>
    <p>{`In this case - I am pretty confident the topic is one a lot of people are
interested in (learning how to buy profitable businesses rather than creating a
business from scratch). So I’m going to partner with an expert.`}</p>
    <p><strong parentName="p">{`We brainstormed 4 different options for expert partners:`}</strong></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`a) `}<a parentName="strong" {...{
            "href": "https://twitter.com/awilkinson"
          }}>{`Andrew Wilkinson`}</a></strong>{` - popular, super
proven track record, good at content.. `}{`*`}{`downside: busy, and because he has
metalab as a cash cow + canada based, he doesn’t do SBA loans which i think
majority of US students would want to use/learn about`}</li>
      <li parentName="ul"><strong parentName="li">{`b) `}<a parentName="strong" {...{
            "href": "https://twitter.com/XavierHelgesen"
          }}>{`Xavier Helgesen`}</a></strong>{` - good track
record, super smart, especially with creative financing/deal structures to
minimize cash needed and minimize downside `}{`*`}{`downside: most people don’t knwo
who he is. so although he knows his shit, he doesn’t also bring students in
off his name`}</li>
      <li parentName="ul"><strong parentName="li">{`c) `}<a parentName="strong" {...{
            "href": "https://twitter.com/RyanBegelman"
          }}>{`Ryan Begelman`}</a></strong>{` - he’s done a shit
ton of research on this topic, and I really like him + like his content voice…
but less established track record of buying businesses than the others`}</li>
      <li parentName="ul"><strong parentName="li">{`d) `}<a parentName="strong" {...{
            "href": "https://twitter.com/BrentBeshore"
          }}>{`Brent Beshore`}</a></strong>{` - great track
record…but only does brick and mortar and not internet biz`}</li>
    </ul>
    <p>{`I sent a little text out to two of them to see if they’re interested in
partnering on this. Simple text: “hey - I’m thinking of creating a course around
buying businesses (aka your jam). Would you want to be my in-house expert to
help me create it? Would be fun + we can share the upside.”`}</p>
    <p>{`My dude Ben also put together a 15-minute Loom walkthrough of how he creates ads
fast and gets them up for testing with a step-by-step breakdown `}<strong parentName="p">{`(`}<em parentName="strong">{`just click
`}<a parentName="em" {...{
            "href": "https://www.loom.com/share/4235db35fcaf447b9d3a0818a9e6c97d"
          }}>{`here`}</a>{` to watch
it`}</em>{`)`}</strong></p>
    <div style={{
      "position": "relative",
      "paddingBottom": "62.5%",
      "height": "0"
    }}>
  <iframe src='https://www.loom.com/embed/4235db35fcaf447b9d3a0818a9e6c97d' frameBorder='0' webkitallowfullscreen mozallowfullscreen allowFullScreen style={{
        "position": "absolute",
        "top": "0",
        "left": "0",
        "width": "100%",
        "height": "100%"
      }}></iframe>
    </div>
    <br />
    <p>{`until tomorrow,`}<br parentName="p"></br>{`
`}{`shaan`}</p>
    <Links links={[{
      title: LinkLabel.Multimedia,
      links: [{
        url: 'https://www.loom.com/share/4235db35fcaf447b9d3a0818a9e6c97d',
        label: 'Course Lead Magnet Ad Setup Loom'
      }]
    }, {
      title: LinkLabel.Web,
      links: [{
        url: 'https://twitter.com/awilkinson',
        label: "Andrew Wilkinson's Twitter"
      }, {
        url: 'https://twitter.com/XavierHelgesen',
        label: "Xavier Helgesen's Twitter"
      }, {
        url: 'https://twitter.com/RyanBegelman',
        label: "Ryan Begelman's Twitter"
      }, {
        url: 'https://twitter.com/BrentBeshore',
        label: "Brent Beshore's Twitter"
      }]
    }]} mdxType="Links" />
    <PostFooter prevButton={{
      text: postMap.the_internet_doesnt_care_about_geography.label,
      slug: slugify(postMap.the_internet_doesnt_care_about_geography.slug)
    }} nextButton={{
      text: postMap.were_not_selling_saddles_here.label,
      slug: slugify(postMap.were_not_selling_saddles_here.slug)
    }} mdxType="PostFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      